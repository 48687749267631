export const addRemoveReasons = [
  "Spoilt Rotis",
  "Missing Referral Credits",
  "Other Shipping Issue",
  "Missing Meal Kits",
  "Incorrect Meal Kits",
  "Substitution Request Missed",
  "Did not like the Meal Kits",
  "Other",
];


export const refundOrderReasons = [
  "First box guarantee",
  "First order cancellation",
  "Recurring order cancellation",
  "Duplicate order cancellation",
  "Missing meals",
  "Did not like the meals",
  "Expired product",
  "Quality Issue with Meals/spoilt meals",
  "Out of stock",
  "Package Lost after delivery",
  "Order not delivered",
  "Delivery delay",
  "Others",
];
