import { gql } from "@apollo/client";

export const GET_USER_TOKEN_MUTATION = gql`
  mutation LoginIntoUser($input: LoginIntoUserInput) {
    loginIntoUser(input: $input)
  }
`;

export const REFUND_AMOUNT_MUTATION = gql`
  mutation Mutation($input: RefundOrderInput!) {
    refundOrder(input: $input)
  }
`;

export const HOLD_ORDER_MUTATION = gql`
  mutation HoldOrder($input: HoldOrderInput!) {
    holdOrder(input: $input) {
      id
    }
  }
`;

export const RELEASE_ORDER_MUTATION = gql`
  mutation ReleaseOrder($input: ReleaseOrderInput!) {
    releaseOrder(input: $input) {
      id
    }
  }
`;

export const ADD_IMMEDIATE_ORDER_MUTATION = gql`
  mutation AddImmediateOrder($input: AddImmediateOrderInput!) {
    addImmediateOrder(input: $input) {
      id
    }
  }
`;

export const ADD_CUMIN_CREDIT_MUTATION = gql`
  mutation AddCuminCredits($input: UpdateCuminCreditsInput) {
    addCuminCredits(input: $input)
  }
`;
export const REMOVE_CUMIN_CREDIT_MUTATION = gql`
  mutation RemoveCuminCredits($input: UpdateCuminCreditsInput) {
    removeCuminCredits(input: $input)
  }
`;

export const LOGIN_WITH_USER_ID_MUTATION = gql`
  mutation LoginViaOrderId($input: LoginViaOrderIdInput!) {
    loginViaOrderId(input: $input) {
      jwtToken
      user {
        email
        id
      }
    }
  }
`;

export const CREATE_FREE_ORDER_MUTATION = gql`
  mutation CreateFreeOrder($input: CreateFreeOrderInput!) {
    createFreeOrder(input: $input)
  }
`;

export const REMOVE_USER_DETAILS_MUTATION = gql`
  mutation removeUserDetails($input: RemoveUserDetailsInput!) {
    removeUserDetails(input: $input)
  }
`;

export const INSERT_INVENTORY_DATA_MUTATION = gql`
  mutation insertInventoryData($input: AddToInventoryInput!) {
    addToInventory(input: $input)
  }
`;

export const UPDATE_INVENTORY_DATA_MUTATION = gql`
  mutation updateInventoryData($input: UpdateInventoryItemsInput!) {
    updateInventoryItems(input: $input)
  }
`;

export const CANCEL_SHOPIFY_ORDER = gql`
  mutation cancelShopifyOrder($input: CancelShopifyOrderInput!) {
    cancelShopifyOrder(input: $input)
  }
`;
