import { gql } from "@apollo/client";

export const ADMIN_LOGIN_MUTATION = gql`
  mutation Mutation($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        id
        isAccountActivated
        admin
        email
      }
    }
  }
`;
