import React, { useState, useContext } from "react";
import { Modal, Button, Typography, TextField, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { LOGIN_WITH_USER_ID_MUTATION } from "../../graphql/mutation/user";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../services/UserContext";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    padding: "30px",
    outline: "none",
    borderRadius: "10px",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
}

function LoginWithUserIdModal(props) {
  const { open, handleClose } = props;
  const [orderId, setOrderId] = useState("");
  const [error, setError] = useState("");

  const { setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  const [loginWithUserId, { loading }] = useMutation(
    LOGIN_WITH_USER_ID_MUTATION
  );

  const styles = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;
    setOrderId(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await loginWithUserId({
        variables: {
          input: {
            orderId,
          },
        },
      });

      if (data) {
        setOrderId("");
        const { jwtToken, user } = data.loginViaOrderId || {};
        if (user && jwtToken) {
          setUserData({
            id: user.id,
            token: jwtToken,
            email: user.email,
          });
          navigate(`/user/${user.id}`);
        }
      }
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.paper} style={getModalStyle()}>
          <Box sx={{ position: "relative" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Login With Order Id
            </Typography>

            <CloseIcon
              style={{
                backgroundColor: "rgba(0,0,0,0.12)",
                borderRadius: "20px",
                position: "absolute",
                right: 0,
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Order Id"
              name="orderId"
              autoComplete="orderId"
              autoFocus
              onChange={handleChange}
              value={orderId}
            />

            {error && (
              <Box>
                <Typography component="p" variant="p" sx={{ color: "red" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              sx={{
                margin: "20px 0",
              }}
              type="submit"
              onClick={handleSubmit}
              disabled={!orderId || loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default LoginWithUserIdModal;
