import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Typography,
  TextField,
  Box,
  InputLabel,
  TextareaAutosize,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { refundOrderReasons } from "./constant";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { REFUND_AMOUNT_MUTATION } from "../../graphql/mutation/user";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    padding: "30px",
    outline: "none",
    borderRadius: "10px",
  },
  dropdownContainer: {
    margin: "20px 0",
    textAlign: "left",
  },
  textArea: {
    marginTop: "20px",
    padding: "10px",
    borderColor: "#0000003b",
    outline: "none",
    borderRadius: "5px",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
}

const initialState = {
  orderId: "",
  refund: "",
  refundPantry: "",
  reason: "",
  otherReason: "",
};

function AdminActionsModal(props) {
  const { open, handleClose, handleOpenToaster } = props;
  const [inputs, setInputs] = useState(initialState);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) {
      setInputs({ ...initialState });
    }
  }, [open]);

  const [refundAmount, { loading }] = useMutation(REFUND_AMOUNT_MUTATION);

  const styles = useStyles();

  const otherReason = inputs.reason === "Others";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await refundAmount({
        variables: {
          input: {
            orderId: inputs.orderId,
            refundAmount: parseInt(inputs.refund),
            refundReason: otherReason ? inputs.otherReason : inputs.reason,
            refundAmountPantry: parseInt(inputs.refundPantry),
          },
        },
      });
      const message = response?.data?.refundOrder;
      if (response) {
        handleClose();
        setInputs({ ...initialState });
        handleOpenToaster(message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.paper} style={getModalStyle()}>
          <Box sx={{ position: "relative" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Refund Order
            </Typography>

            <CloseIcon
              style={{
                backgroundColor: "rgba(0,0,0,0.12)",
                borderRadius: "20px",
                position: "absolute",
                right: 0,
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Order Id"
              name="orderId"
              autoComplete="orderId"
              autoFocus
              onChange={handleChange}
              value={inputs.orderId}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Refund Amount Meals (in cents)"
              name="refund"
              autoComplete="refundAmount"
              autoFocus
              onChange={handleChange}
              value={inputs.refundAmount}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Refund Amount Pantry (in cents)"
              name="refundPantry"
              autoComplete="refundAmountPantry"
              autoFocus
              onChange={handleChange}
              value={inputs.refundPantry}
            />

            <Box className={styles.dropdownContainer}>
              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Reason
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.reason}
                  label="Select Reason"
                  name="reason"
                  onChange={handleChange}
                >
                  {refundOrderReasons.map((reason, index) => (
                    <MenuItem value={reason} key={index}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {otherReason && (
                <TextareaAutosize
                  minRows={8}
                  name="otherReason"
                  placeholder="Add Reason"
                  style={{ width: "100%" }}
                  value={otherReason ? inputs.otherReason : ""}
                  className={styles.textArea}
                  onChange={handleChange}
                  disabled={!otherReason}
                />
              )}
            </Box>

            {error && (
              <Box>
                <Typography component="p" variant="p" sx={{ color: "red" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              sx={{
                margin: "20px 0",
              }}
              type="submit"
              onClick={handleSubmit}
              disabled={
                (!inputs.refund && !inputs.refundPantry) ||
                !inputs.orderId ||
                loading ||
                (otherReason ? !inputs.otherReason : !inputs.reason)
              }
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default AdminActionsModal;
