import { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import UserContext from "../services/UserContext";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";

export default function Navbar({ title, containerStyle = {} }) {
  const [openSidebar, setOpenSidebar] = useState(false);
  const { setToken } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    setToken("");
    navigate("/login");
  };

  const closeSidebar = () => setOpenSidebar(false);

  return (
    <Box sx={{ flexGrow: 1, ...containerStyle }}>
      <Sidebar openSidebar={openSidebar} closeSidebar={closeSidebar} />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpenSidebar(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title ?? `TCC: Admin`}
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
