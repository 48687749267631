import { Box, Button } from "@mui/material";
import Navbar from "../../components/NavBar";
import PageLoader from "../../components/PageLoader";
import { useRef, useState } from "react";
import csvtojson from "csvtojson";
import { useMutation } from "@apollo/client";
import { INSERT_INVENTORY_DATA_MUTATION } from "../../graphql/mutation/user";
import { format } from "date-fns";
import Snackbar from "../../components/Snackbar";
import InventoryTable from "./Table";

const assertDateFormat = (obj) => {
  const dispatchDate = obj.dispatchDate;
  const expireDate = obj.expireDate;
  const formattedDispatchDate = format(dispatchDate, "yyyy-MM-dd");
  const formattedExpireDate = format(expireDate, "yyyy-MM-dd");

  if (
    dispatchDate !== formattedDispatchDate ||
    expireDate !== formattedExpireDate
  ) {
    throw new Error("Date should be in YYYY-MM-DD format!");
  }
};

const Inventory = () => {
  const [loading, setLoading] = useState(false);
  const [openToaster, setOpenToaster] = useState(false);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const inputRef = useRef(null);
  const [insertInventoryData] = useMutation(INSERT_INVENTORY_DATA_MUTATION);

  const handleCloseToaster = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToaster(false);
    setError("");
    setSuccessMsg("");
  };

  const handleFileChange = (e) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = () => {
      csvtojson({
        headers: [
          "dispatchDate",
          "boxNumber",
          "trackingNumber",
          "carrier",
          "productCode",
          "quantity",
          "batchNumber",
          "expireDate",
          "destination",
        ],
      })
        .fromString(reader.result)
        .subscribe((obj) => {
          assertDateFormat(obj);
          obj.quantity = parseInt(obj.quantity);
        })
        .then((res) => {
          insertInventoryData({
            variables: {
              input: {
                data: res,
              },
            },
          }).then((mutationRes) => {
            const success = mutationRes?.data?.addToInventory;
            if (success) {
              setSuccessMsg("Inventory data added!");
              setError("");
              setOpenToaster(true);
            }
            setLoading(false);
          });
        })
        .catch((err) => {
          setLoading(false);
          setOpenToaster(true);
          setError(err.message);
        })
        .finally(() => {
          inputRef.current.value = null;
        });
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <>
      <Snackbar
        open={openToaster}
        handleClose={handleCloseToaster}
        message={error || successMsg}
        error={!!error}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Navbar
          title="Inventory"
          containerStyle={{
            flexGrow: "unset",
          }}
        />
        {loading ? (
          <div
            style={{
              position: "fixed",
              zIndex: 9,
              width: "100vw",
              height: "100vh",
            }}
          >
            <PageLoader />
          </div>
        ) : null}
        <Box
          sx={{
            padding: "16px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="file"
            style={{
              display: "none",
            }}
            id="upload-csv"
            onChange={handleFileChange}
            ref={inputRef}
            multiple={false}
            accept=".csv"
          />
          <Box>
            <Button
              variant="contained"
              onClick={() => inputRef.current.click()}
            >
              Upload CSV
            </Button>
          </Box>
          <InventoryTable />
        </Box>
      </div>
    </>
  );
};

export default Inventory;
