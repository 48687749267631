import React, { useState, useEffect, createContext } from "react";
import { isAuthenticated } from "./AuthService";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Login from "../pages/Login";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoggedIn = async () => {
      let userToken = isAuthenticated();
      if (!userToken) {
        localStorage.removeItem("jwt");
        userToken = "";
        navigate("/login");
      }

      setToken(userToken);
    };
    checkLoggedIn();
  }, []);

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
        userData,
        setUserData,
      }}
    >
      {token ? (
        children
      ) : (
        <Link to="/login" style={{ textDecoration: "none", color: "unset" }}>
          <Login />
        </Link>
      )}
    </UserContext.Provider>
  );
};

export default UserContext;
