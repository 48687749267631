import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useMutation } from "@apollo/client";
import { ADMIN_LOGIN_MUTATION } from "../../graphql/mutation/login";
import { useNavigate } from "react-router-dom";
import UserContext from "../../services/UserContext";
import CircularProgress from "@mui/material/CircularProgress";

const initialState = {
  email: "",
  password: "",
};

export default function Login() {
  const [input, setInput] = useState(initialState);
  const [adminLogin, { loading }] = useMutation(ADMIN_LOGIN_MUTATION);
  const [errors, setErrors] = useState(initialState);
  const [error, setError] = useState("");

  const { token, setToken } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    const errors = { ...initialState };
    errors.email = input.email
      ? /.+@.+..+/.test(input.email)
        ? ""
        : "Invalid Email"
      : "Email is Required";
    errors.password = input.password ? "" : "Password is Required";
    setErrors({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const { data } = await adminLogin({
          variables: {
            input,
          },
        });
        const { login } = data || {};
        if (login.user.admin) {
          localStorage.setItem("jwt", login.token);
          setToken(login.token);
          setError("");
          navigate("/");
        } else {
          setError("User is not Admin");
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={input.email}
              onChange={handleChange}
              error={errors.email.length > 0}
              helperText={errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={input.password}
              onChange={handleChange}
              error={errors.password.length > 0}
              helperText={errors.password}
            />
            {error && (
              <Box>
                <Typography component="p" variant="p" sx={{ color: "red" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress /> : "Sign In"}
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
