import { Box } from "@mui/material";
import logo from "../../assets/logo.png";
import { useStyles } from "./useStyles";

const PageLoader = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.mainContainer}
      sx={{
        backgroundColor: "#F0EAE3",
        height: "100vh",
      }}
    >
      <div className={classes.logo}>
        <img src={logo} alt="logo" />
      </div>
    </Box>
  );
};

export default PageLoader;
