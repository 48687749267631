import React, { useEffect, useState } from "react";
import { Modal, Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { REMOVE_USER_DETAILS_MUTATION } from "../../graphql/mutation/user";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    padding: "30px",
    outline: "none",
    borderRadius: "10px",
  },
  info: {
    textAlign: "left",
    marginTop: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "right",
    gap: "20px",
  },
  error: {
    color: "red",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
}

function RemoveUserDetailsModal(props) {
  const { open, handleClose, handleOpenToaster, userId } = props;
  const styles = useStyles();
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) {
      setError("");
    }
  }, [open]);

  const [removeUserDetails, { loading }] = useMutation(
    REMOVE_USER_DETAILS_MUTATION
  );

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    try {
      const response = await removeUserDetails({
        variables: {
          input: {
            userId,
          },
        },
      });
      if (response?.data?.removeUserDetails) {
        handleClose();
        handleOpenToaster("User Details Removed Successfully");
      } else {
        setError("Something went wrong!");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.paper} style={getModalStyle()}>
          <Box sx={{ position: "relative" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure?
            </Typography>

            <CloseIcon
              style={{
                backgroundColor: "rgba(0,0,0,0.12)",
                borderRadius: "20px",
                position: "absolute",
                right: 0,
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>
          <div className={styles.info}>
            <div>This will:</div>
            <ul>
              <li>Delete the user</li>
              <li>Cancel all their subscriptions</li>
              <li>Remove card details from subscriptions & orders</li>
            </ul>
          </div>
          {error ? <div className={styles.error}>{error}</div> : null}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            className={styles.buttonContainer}
          >
            <Button
              variant="contained"
              sx={{
                margin: "20px 0",
              }}
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "Yes"}
            </Button>
            <Button
              sx={{
                margin: "20px 0",
              }}
              onClick={handleClose}
            >
              No
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default RemoveUserDetailsModal;
