import React from "react";
import {
  Drawer,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import RedeemIcon from "@mui/icons-material/Redeem";
import InventoryIcon from "@mui/icons-material/Inventory";
import { NavLink } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const Sidebar = (props) => {
  const { openSidebar, closeSidebar } = props;
  return (
    <Box>
      <Drawer
        open={openSidebar}
        anchor="left"
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
          },
        }}
        onClose={closeSidebar}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={closeSidebar}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {[
            { text: "Home", navigateTo: "/", icon: <HomeIcon /> },
            {
              text: "Add Free Order",
              navigateTo: "/add-free-order",
              icon: <RedeemIcon />,
            },
            {
              text: "View Free Orders",
              navigateTo: "/free-orders",
              icon: <InventoryIcon />,
            },
            {
              text: "Inventory",
              navigateTo: "/inventory",
              icon: <LocalOfferIcon />,
            },
          ].map((item, index) => (
            <NavLink
              to={item.navigateTo}
              style={(navData) =>
                navData.isActive
                  ? {
                      color: "#D46A19",
                      textDecoration: "none",
                      fontWeight: 600,
                    }
                  : { textDecoration: "none", color: "unset" }
              }
            >
              <ListItem key={index} sx={{ padding: 0 }}>
                <ListItemButton
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "20px",
                  }}
                >
                  {item.icon}
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
