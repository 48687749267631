import Home from "./pages/Home";
import User from "./pages/User";
import AddFreeOrder from "./pages/FreeOrder/AddFreeOrder";
import ViewFreeOrders from "./pages/FreeOrder/ViewFreeOrders";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Inventory from "./pages/Inventory";

const BrowseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/user/:userId" element={<User />} />
      <Route path="/add-free-order" element={<AddFreeOrder />} />
      <Route path="/free-orders" element={<ViewFreeOrders />} />
      <Route path="/login" element={<Login />} />
      <Route path="/inventory" element={<Inventory />} />
    </Routes>
  );
};

export default BrowseRoutes;
