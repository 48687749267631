import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_FREE_ORDERS_QUERY } from "../../graphql/queries/user";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PageLoader from "../../components/PageLoader";
import Navbar from "../../components/NavBar";

const ViewFreeOrders = () => {
  const [rows, setRows] = useState([]);
  const { data, loading, error, refetch } = useQuery(GET_ALL_FREE_ORDERS_QUERY);

  useEffect(() => {
    if (data) {
      updateRows(data.getAdminAllFreeOrders);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const updateRows = (data) => {
    const rows = data.map((row) => {
      const {
        address_line_1,
        address_line_2,
        city,
        zip_code,
        state,
        country_code,
      } = row.shipping_address || {};

      const products = row.order_products
        .map((product) => `${product.product.title} x ${product.quantity}`)
        .join(", ");

      return {
        id: row.id,
        name: `${row.shipping_address.first_name} ${row.shipping_address.last_name}`,
        address: `${address_line_1} ${address_line_2} ${city}, ${zip_code}, ${state}, ${country_code}`,
        products,
        trackingId: row.trackingId || "-",
      };
    });
    setRows(rows);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const products = (props) => {
    const { products } = props.row || {};
    return (
      <BootstrapTooltip title={products} placement="top">
        <Typography>Products</Typography>
      </BootstrapTooltip>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "address",
      headerName: "Address",
      width: 400,
    },
    {
      field: "products",
      headerName: "Products",
      width: 100,
      renderCell: products,
    },
    {
      field: "trackingId",
      headerName: "Tracking ID",
      width: 100,
    },
  ];

  if (loading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  return (
    <div>
      <Navbar title="View Free Orders" />
      {rows.length > 0 ? (
        <>
          <Box sx={{ height: 650, width: "950px", margin: "20px auto" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
            />
          </Box>
        </>
      ) : (
        <Typography align="center" variant="h6">
          No Record Found
        </Typography>
      )}
    </div>
  );
};

export default ViewFreeOrders;
