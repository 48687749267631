import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PENDING_SUBSCRIPTIONS_QUERY } from "../../graphql/queries/user";
import { CREATE_FREE_ORDER_MUTATION } from "../../graphql/mutation/user";
import { GET_ALL_FREE_ORDERS_QUERY } from "../../graphql/queries/user";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageLoader from "../../components/PageLoader";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Snackbar from "../../components/Snackbar";
import Navbar from "../../components/NavBar";
import CircularProgress from "@mui/material/CircularProgress";

const AddFreeOrder = () => {
  const [rows, setRows] = useState([]);
  const [openToaster, setOpenToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState(false);
  const [toastError, setToastError] = useState("");
  const [buttonId, setButtonId] = useState(null);
  const { data, loading, error, refetch } = useQuery(
    GET_PENDING_SUBSCRIPTIONS_QUERY
  );
  const [createFreeOrder, { loading: freeOrderLoading }] = useMutation(
    CREATE_FREE_ORDER_MUTATION
  );

  useEffect(() => {
    if (data) {
      updateRows(data.getAdminPendingSubscription);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const updateRows = (data) => {
    const rows = data.map((row) => {
      const {
        address_line_1,
        address_line_2,
        city,
        zip_code,
        state,
        country_code,
        first_name,
        last_name,
      } = row.shipping_address || {};

      const products = row.subscription_products
        .map((product) => `${product.product.title} x ${product.quantity}`)
        .join(", ");

      return {
        id: row.id,
        name: row.shipping_address ? `${first_name} ${last_name}` : "-",
        address: row.shipping_address
          ? `${address_line_1} ${address_line_2} ${city}, ${zip_code}, ${state}, ${country_code}`
          : "-",
        products,
      };
    });
    setRows(rows);
  };
  const handleCloseToaster = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToasterMessage("");
    setToastError("");
    setOpenToaster(false);
  };

  const handleCreateFreeOrder = async (subscriptionId) => {
    setButtonId(subscriptionId);
    try {
      const { data } = await createFreeOrder({
        variables: {
          input: { subscriptionId },
        },
        refetchQueries: [
          {
            query: GET_ALL_FREE_ORDERS_QUERY,
          },
        ],
      });
      if (data) {
        setOpenToaster(true);
        setToasterMessage(
          `Order #${data.createFreeOrder} generated Successfully`
        );
      }
    } catch (err) {
      setOpenToaster(true);
      setToastError(err.message);
    }
    setButtonId(null);
  };

  const generateFreeOrderButton = (props) => {
    const { row } = props;
    const { id } = row || {};
    return (
      <Button
        variant="contained"
        onClick={() => handleCreateFreeOrder(id)}
        disabled={freeOrderLoading}
        sx={{ width: "200px" }}
      >
        {freeOrderLoading && buttonId === id ? (
          <CircularProgress size={25} />
        ) : (
          "Generate Free Order"
        )}
      </Button>
    );
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const products = (props) => {
    const { products } = props.row || {};
    return (
      <BootstrapTooltip title={products} placement="top">
        <Typography>Products</Typography>
      </BootstrapTooltip>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Subscription ID",
      width: 120,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "address",
      headerName: "Address",
      width: 400,
    },
    {
      field: "products",
      headerName: "Products",
      width: 100,
      renderCell: products,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: generateFreeOrderButton,
    },
  ];

  if (loading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  return (
    <div>
      <Navbar title="Add Free Order" />
      <Snackbar
        open={openToaster}
        handleClose={handleCloseToaster}
        message={toastError.length > 0 ? toastError : toasterMessage}
        error={toastError.length > 0}
      />
      {rows.length > 0 ? (
        <>
          <Box sx={{ height: 500, width: "1080px", margin: "20px auto" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
            />
          </Box>
        </>
      ) : (
        <Typography align="center" variant="h6">
          No Record Found
        </Typography>
      )}
    </div>
  );
};

export default AddFreeOrder;
