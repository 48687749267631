import React, { useState, useEffect } from "react";
import { Modal, Button, Typography, TextField, Box, Select, MenuItem, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMutation, useQuery } from "@apollo/client";
import { HOLD_ORDER_MUTATION, UPDATE_INVENTORY_DATA_MUTATION } from "../../graphql/mutation/user";
import CloseIcon from "@mui/icons-material/Close";
import { GET_ALL_INVENTORY, GET_INVENTORY_DESTINATIONS } from "../../graphql/queries/user";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    padding: "30px",
    outline: "none",
    borderRadius: "10px",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
}

function StagedWithDestinationModal(props) {
  const { open, handleClose, handleOpenToaster } = props;
  const [destination, setDestination] = useState("");
  const [error, setError] = useState("");

  const { data, loading: destinationsLoading } = useQuery(GET_INVENTORY_DESTINATIONS)
  const inventoryDestinations = data?.getAdminInventoryDestinations ?? [];

  useEffect(() => {
    if (!open) {
      setDestination("");
      setError("");
    }
  }, [open]);

  const [updateInventoryData, { loading }] = useMutation(
    UPDATE_INVENTORY_DATA_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ALL_INVENTORY,
          variables: {
            input: {
              filters: props.queryFilters,
            },
          },
        },
      ],
    }
  );

  const styles = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;
    setDestination(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!destination) {
      setError("Please select a destination");
      return
    }
    try {
      const response = await updateInventoryData({
        variables: {
          input: {
            ids: props.selectedRows.map((i) => i.id),
            status: props.status,
            destination,
          },
        },
      });
      if (response) {
        handleClose();
        setDestination("");
        handleOpenToaster("Marked Staged with Destination successfully!");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.paper} style={getModalStyle()}>
          <Box sx={{ position: "relative" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Mark Staged with Destination
            </Typography>

            <CloseIcon
              style={{
                backgroundColor: "rgba(0,0,0,0.12)",
                borderRadius: "20px",
                position: "absolute",
                right: 0,
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <InputLabel id="destination">Select Destination Below</InputLabel>
            <Select onChange={handleChange} required placeholder="Select destination" label="Destination" value={destination} fullWidth labelId="destination">
              {
                inventoryDestinations?.map(item => <MenuItem value={item.name}>{item.name}</MenuItem>)
              }
            </Select>

            {error && (
              <Box>
                <Typography component="p" variant="p" sx={{ color: "red" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              sx={{
                margin: "20px 0",
              }}
              type="submit"
              onClick={handleSubmit}
              disabled={loading || destinationsLoading}
            >
              {(loading || destinationsLoading) ? "Loading..." : "Submit"}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default StagedWithDestinationModal;
