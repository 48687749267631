import React, { useState } from "react";
import {
  Modal,
  Button,
  Typography,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  ADD_CUMIN_CREDIT_MUTATION,
  REMOVE_CUMIN_CREDIT_MUTATION,
} from "../../graphql/mutation/user";
import { addRemoveReasons } from "./constant";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    padding: "30px",
    outline: "none",
    borderRadius: "10px",
  },
  inputWrapper: {
    display: "flex",
    columnGap: "20px",
  },
  dropdownContainer: {
    margin: "20px 0",
    textAlign: "left",
  },
  textArea: {
    marginTop: "20px",
    padding: "10px",
    borderColor: "#0000003b",
    outline: "none",
    borderRadius: "5px",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
}

const initialState = {
  amount: "",
  orderId: "",
  reason: "",
  notes: "",
};

function AdminActionsModal(props) {
  const { open, handleClose, handleOpenToaster, type } = props;
  const [inputs, setInputs] = useState(initialState);
  const [error, setError] = useState("");
  const { userId } = useParams();

  const [addCredit, { loading: addLoading }] = useMutation(
    ADD_CUMIN_CREDIT_MUTATION
  );
  const [removeCredit, { loading: removeLoading }] = useMutation(
    REMOVE_CUMIN_CREDIT_MUTATION
  );

  const styles = useStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const variables = {
        input: {
          userId,
          amount: parseInt(inputs.amount),
          orderId: inputs.orderId,
          adminReason: inputs.reason,
          adminNotes: inputs.notes,
        },
      };
      const response =
        type === "Add Credit"
          ? await addCredit({ variables })
          : await removeCredit({ variables });

      if (response) {
        handleClose();
        setInputs({ ...initialState });
        handleOpenToaster(
          `Credit ${type === "Add Credit" ? "Added" : "Removed"} Successfully`
        );
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.paper} style={getModalStyle()}>
          <Box sx={{ position: "relative" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {type}
            </Typography>

            <CloseIcon
              style={{
                backgroundColor: "rgba(0,0,0,0.12)",
                borderRadius: "20px",
                position: "absolute",
                right: 0,
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box className={styles.inputWrapper}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Amount (in cents)"
                name="amount"
                autoComplete="amount"
                autoFocus
                onChange={handleChange}
                value={inputs.amount}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Order Id"
                name="orderId"
                autoComplete="orderId"
                autoFocus
                onChange={handleChange}
                value={inputs.orderId}
              />
            </Box>

            <Box className={styles.dropdownContainer}>
              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Reason
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.reason}
                  label="Select Reason"
                  name="reason"
                  onChange={handleChange}
                >
                  {addRemoveReasons.map((reason, index) => (
                    <MenuItem value={reason} key={index}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextareaAutosize
                minRows={8}
                name="notes"
                placeholder="Add Notes"
                style={{ width: "100%" }}
                value={inputs.notes}
                className={styles.textArea}
                onChange={handleChange}
              />
            </Box>

            {error && (
              <Box>
                <Typography component="p" variant="p" sx={{ color: "red" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              disabled={
                !inputs.amount ||
                !inputs.orderId ||
                !inputs.reason ||
                addLoading ||
                removeLoading
              }
            >
              {addLoading || removeLoading ? "Loading..." : type}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default AdminActionsModal;
