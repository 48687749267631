import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/NavBar";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_USERS_QUERY,
  GET_ME_QUERY,
} from "../../graphql/queries/user.js";
import { GET_USER_TOKEN_MUTATION } from "../../graphql/mutation/user.js";
import { useNavigate } from "react-router-dom";
import UserContext from "../../services/UserContext";
import PageLoader from "../../components/PageLoader";
import Snackbar from "../../components/Snackbar";
// import Modal from "../Home/ManageAccountModal";
import LoginWithUserIdModal from "./LoginWithUserIdModal";

export default function Home() {
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  // const [open, setOpen] = useState(false);
  // const [adminPassword, setAdminPassword] = useState("");
  // const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState("");
  const [searchedData, setSearchedData] = useState(false);
  const [open, setOpen] = useState(false);

  const { data, loading } = useQuery(GET_ALL_USERS_QUERY);
  const { data: adminData, loading: adminLoading } = useQuery(GET_ME_QUERY);

  const [getSearchData, { loading: searchLoading }] =
    useLazyQuery(GET_ALL_USERS_QUERY);

  const [getUserToken, { loading: userTokenLoading }] = useMutation(
    GET_USER_TOKEN_MUTATION
  );

  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);

  const updateRows = (data) => {
    const { getAllUsers } = data || {};
    if (getAllUsers) {
      const rows = getAllUsers.map((user) => ({
        email: user.email,
        id: user.id,
        account_status: user.isAccountActivated ? "Activated" : "Deactivated",
        cumin_credits: `${user.creditsCurrencySymbol || ""}${(
          user.cuminCredits / 100
        ).toFixed(2)}`,
        actions: user,
      }));
      setRows(rows);
    }
  };

  useEffect(() => {
    updateRows(data);
  }, [data]);

  useEffect(() => {
    if (!searchText) {
      handleSearch();
    }
  }, [searchText]);

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   setAdminPassword(value);
  // };

  const handleCloseToaster = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  const handleSubmit = async (userId, email) => {
    // e.preventDefault();
    // const { userId, email } = userInfo;
    const { email: adminEmail } = adminData.getMe || {};
    if (userId && email && adminEmail) {
      try {
        const { data } = await getUserToken({
          variables: {
            input: {
              email: adminEmail,
              userId,
            },
          },
        });
        const { loginIntoUser } = data || {};
        if (loginIntoUser) {
          setUserData({
            id: userId,
            token: loginIntoUser,
            email,
          });
          navigate(`/user/${userId}`);
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const renderButton = (props) => {
    const { row } = props;
    const { id, email } = row || {};
    return (
      <Button
        variant="contained"
        onClick={() => {
          // setOpen(true);
          // setUserInfo({ userId: id, email });
          handleSubmit(id, email);
        }}
      >
        Manage Account
      </Button>
    );
  };

  const handleSearch = async () => {
    const { data } = await getSearchData({
      variables: {
        filters: {
          searchText,
        },
      },
    });
    if (data) {
      updateRows(data);
      setSearchedData(true);
    }
    if (!searchText) {
      setSearchedData(false);
    }
  };

  const columns = [
    {
      field: "email",
      headerName: "Email ID",
      width: 250,
    },
    {
      field: "id",
      headerName: "User ID",
    },
    {
      field: "account_status",
      headerName: "Account Status",
      width: 150,
    },
    {
      field: "cumin_credits",
      headerName: "Cumin Credits",
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: renderButton,
    },
  ];

  if (loading || userTokenLoading || adminLoading) return <PageLoader />;

  return (
    <>
      <Navbar title="Admin Home" />
      {loading ? (
        "Loading..."
      ) : (
        <Box>
          {/* <Modal
            open={open}
            handleClose={handleClose}
            handleChange={handleChange}
            adminPassword={adminPassword}
            handleSubmit={handleSubmit}
            error={error}
          /> */}
          <LoginWithUserIdModal open={open} handleClose={handleClose} />
          <Snackbar
            open={error.length > 0}
            handleClose={handleCloseToaster}
            message={error}
            error
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "20px",
              padding: "20px 0",
            }}
          >
            <TextField
              margin="normal"
              label="Search CTA"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              type="search"
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              disabled={searchLoading}
            >
              {searchLoading ? "Searching..." : "Search"}
            </Button>
            <Button variant="contained" onClick={() => setOpen(true)}>
              Login With Order Id
            </Button>
          </Box>

          {rows.length > 0 ? (
            <>
              <Box sx={{ height: 1000, width: "72%", margin: "20px auto" }}>
                {searchedData && (
                  <Typography variant="h6">Searched Records</Typography>
                )}
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  checkboxSelection
                  disableSelectionOnClick
                />
              </Box>
            </>
          ) : (
            <Typography align="center" variant="h6">
              No Record Found
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}
